import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
// views
import home from "../views/Home.vue";
import login from "../views/Login.vue";
import group from "../views/Group.vue";
import forgotPassword from "../views/ForgotPassword.vue";
import resetPassword from "../views/ResetPassword.vue";
import userGroup from "../views/UserGroup.vue";
import userApi from "../api/user";
import authService from "@/services/auth";
Vue.use(VueRouter);

axios.defaults.headers.common["Authorization"] =
  "Bearer " + window.localStorage.token;

const routes = [
  {
    path: "/",
    component: home,
    meta: { title: "inicio" },
    beforeEnter: (to, from, next) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("token");
      let link;

      if (token) {
        authService.clearLocalStorage();
        link = window.localStorage.site + "/api/verifica_token";
        axios
          .post(link, {
            token: token,
          })
          .then((response) => {
            let user = {
              display_name: response.data.nombre,
              invitacion_id: response.data.id,
              email: response.data.email,
              can_chat: response.data.can_chat,
            };
            window.localStorage.token = token; // TODO: remove
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.token; // TODO: remove
            window.localStorage.invitado = JSON.stringify(user);
            next();
          })
          .catch((error) => {
            console.log(error);
            alert(
              "Tu código de acceso ya expiró, por favor contacta al administrador"
            );
            router.push("/login");
          });
      } else {
        if (!authService.checkLocalStorageForAuth()) {
          authService.clearLocalStorage();
          router.push("/login");
        }

        if (window.localStorage.capabilities == 10) {
          userApi
            .getCapabilities()
            .then((response) => {
              window.localStorage.capabilities = response.data;
              next();
            })
            .catch(() => {
              authService.clearLocalStorage();
              router.push("/login");
            });
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/group",
    component: group,
    meta: { title: "grupos" },
    beforeEnter: (to, from, next) => {
      const capabilities = window.localStorage.capabilities;
      if (capabilities === "10") {
        next();
        return;
      }
      router.push("/");
    },
  },
  {
    path: "/user-group",
    component: userGroup,
    meta: { title: "grupos" },
    beforeEnter: (to, from, next) => {
      const capabilities = window.localStorage.capabilities;
      if (capabilities === "10") {
        next();
        return;
      }
      router.push("/");
    },
  },
  { path: "/login", component: login, meta: { title: "inicio" } },
  {
    path: "/forgotPassword",
    component: forgotPassword,
    meta: { title: "recuperación de contraseña" },
  },
  {
    path: "/reset",
    component: resetPassword,
    meta: { title: "reset password" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
