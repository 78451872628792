<template>
  <div>
    <b-modal ref="connections" id="connections" size="xl" :title="title" scrollable>
      <b-tabs content-class="mt-3">
        <b-tab title="Miembros" active>
          <div class="divInputSearch">
            <input 
              class="searchInput"
              type="text"
              v-model="searchQueryUsers" 
              placeholder="Buscar por nombre, email o ip"
            >
          </div>
          <div class="container" style="background: white">
            <div class="row">
              <div class="col-12">
                <b-table
                  :items="filteredUsersConnected"
                  :fields="fields"
                  sort-by="name"
                  sort-icon-left
                  sticky-header="800px"
                  striped
                  hover
                  small
                >
                  <template #cell(disconnect)="data">
                    <button
                      class="btn btn-danger"
                      v-if="data.item.type === 'invitado'"
                      v-on:click="dropGuests(data.item.id)"
                    >
                      Desconectar
                    </button>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Visitas">
          <div class="divInputSearch">
            <input 
              class="searchInput"
              type="text"
              v-model="searchQueryGuests" 
              placeholder="Buscar por nombre, email o ip"
            >
          </div>
          <div class="container" style="background: white">
            <div class="row">
              <div class="col-12">
                <b-table
                  :items="filteredGuestsConnected"
                  :fields="fields"
                  sort-by="name"
                  sort-icon-left
                  sticky-header="800px"
                  striped
                  hover
                  small
                >
                  <template #cell(disconnect)="data">
                    <button
                      class="btn btn-danger"
                      v-if="data.item.type === 'invitado'"
                      v-on:click="dropGuests(data.item.id)"
                    >
                      Desconectar
                    </button>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="secondary" @click="cancel()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import guests from "../mixins/guests";

export default {
  data: function () {
    return {
      searchQueryUsers: '',
      searchQueryGuests: '',
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "email", sortable: true },
        { key: "type", sortable: true },
        { key: "address", sortable: true },
        { key: "version", sortable: true },
        { key: "time", sortable: false },
        "disconnect",
      ],
    };
  },
  mixins: [guests],
  computed: {
    filteredUsersConnected() {
      return this.connections.filter(users => {
        const query = this.searchQueryUsers.toLowerCase();
        return users.name.toLowerCase().includes(query) 
          || users.email.toLowerCase().includes(query) 
          || users.address.toLowerCase().includes(query);
      }).filter(x => x.type === "usuario");
    },
    filteredGuestsConnected() {
      return this.connections.filter(users => {
        const query = this.searchQueryGuests.toLowerCase();
        return users.name.toLowerCase().includes(query) 
          || users.email.toLowerCase().includes(query) 
          || users.address.toLowerCase().includes(query);
      }).filter(x => x.type !== "usuario");
    },    
    ...mapGetters({
      connections: "connections/getConnections",
    }),
    title: function () {
      return `Conexiones activas: ${this.connections.length}`;
    },
  },
};
</script>

<style scoped>
.divInputSearch{
  padding-left: 15px;
  padding-bottom: 20px;
}

.searchInput{
  width: 35%;
}
</style>