export default {
  namespaced: true,
  state: {
    activeConnections: [],
  },
  mutations: {
    updateConnections(state, connections) {
      state.activeConnections = connections;
    },
  },
  getters: {
    getConnections(state) {
      return state.activeConnections;
    },
  },
};
