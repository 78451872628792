<template>
  <div class="change-password-form">
    <b-modal
      ref="changePassword"
      id="changePassword"
      title="Cambiar contraseña"
    >
      <div class="modal-body">
        <div class="form-group" v-if="!authenticated">
          <label>Primero ingrese su contraseña actual</label>
          <input
            type="password"
            class="form-control"
            placeholder="Contraseña"
            v-model="currentPassword"
          />
        </div>
        <div v-else>
          <div class="form-group">
            <label>Ingrese su nueva contraseña</label>
            <input
              type="password"
              class="form-control"
              placeholder="Contraseña"
              v-model="password"
            />
          </div>
          <div class="form-group">
            <label>Reingrese su nueva contraseña</label>
            <input
              type="password"
              class="form-control"
              placeholder="Contraseña"
              v-model="password_confirmation"
            />
          </div>
        </div>
      </div>
      <b-spinner v-if="loading" label="Loading..."></b-spinner>
      <b-alert variant="danger" :show="authError">{{ error }}</b-alert>
      <b-alert variant="danger" :show="newPassError">{{ error }}</b-alert>
      <template #modal-footer="{ cancel }">
        <b-button
          v-if="!authenticated"
          size="md"
          variant="primary"
          class="continue"
          @click="authenticate()"
          >Continuar</b-button
        >
        <b-button
          v-else
          size="md"
          variant="primary"
          class="continue"
          @click="changePassword()"
          >Listo</b-button
        >
        <b-button size="md" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import validations from "../mixins/validations";
import authService from "@/services/auth";

export default {
  data: function () {
    return {
      currentPassword: "",
      error: "",
      loading: false,
      authError: false,
      newPassError: false,
      authenticated: false,
      password: "",
      password_confirmation: "",
    };
  },
  mixins: [validations],
  mounted() {
    let self = this;
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if (modalId == "changePassword") {
        self.id = this.authenticated = false;
        self.currentPassword = null;
        self.password = null;
        self.password_confirmation = null;
      }
    });
  },
  methods: {
    cancel: function () {
      this.currentPassword = "";
      this.loading = false;
      this.authauthenticated = false;
      this.password = "";
      this.password_confirmation = "";
    },
    authenticate: function () {
      var link = window.localStorage.site + "/api/login";
      this.loading = true;

      axios
        .post(link, {
          user_login: this.$root.user.user_login,
          user_pass: this.currentPassword,
        })
        .then((response) => {
          window.localStorage.token = response.data.success.token;
          this.authenticated = true;
          this.authError = false;
          this.loading = false;
        })
        .catch(() => {
          this.authError = true;
          this.error = "La contraseña es incorrecta";
          this.loading = false;
        });
    },
    changePassword: function () {
      if (this.loading) {
        return;
      }

      if (!this.validPassword()) {
        this.newPassError = true;
        return;
      }

      this.loading = true;
      var link = window.localStorage.site + "/api/user/change-password";
      axios
        .post(link, {
          password: this.password,
        })
        .then(() => {
          this.$refs["changePassword"].hide();
          this.newPassError = false;
          this.error = "Contraseña incorrecta";
          this.$alert(
            "Su contraseña se ha cambiado con éxito! Ahora será redireccionado para iniciar sesión nuevamente.",
            "Listo",
            "success"
          ).then(() => {
            this.logout();
            this.loading = false;
          });
        })
        .catch(() => {
          this.error =
            "Ha ocurrudo un error intentando cambiar su contraseña. Por favor comuníquelo con el equipo de soporte.";
          this.loading = false;
        });
    },
    logout: function () {
      authService.logout(this.$root.socket, this.$router);
      delete this.user;
    },
  },
};
</script>

<style scoped>
input,
input:focus {
  background-color: #505050;
  outline: none;
  border: none;
  padding: 22px;
  color: #fff;
}

input::placeholder {
  color: #cacaca;
}

.continue {
  background-color: #2d92cb;
}

.continue:hover {
  background-color: #ffffff;
  color: #373737;
}
</style>
