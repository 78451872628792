<template>
  <div id="app">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      user: [],
      page: "Inicio",
      socket: "",
      cortina: "430524192",
    };
  },

  created: function () {
    if (window.localStorage.token) {
      this.setAuthorization(window.localStorage.token);
    }

    let user = window.localStorage.user
      ? window.localStorage.user
      : window.localStorage.invitado;

    if (user) {
      this.user = JSON.parse(user);
      this.$root.user = this.user;
      this.$root.capabilities = window.localStorage.capabilities;
    }

    window.addEventListener("message", this.onUpdateMessage);
  },
  methods: {
    setAuthorization: function (codigo) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + codigo;
    },
    onUpdateMessage: function (message) {
      if (!message.data.type) return;
      if (message.data.type !== "update-found") return;
      this.$vToastify.success(
        "Para descargarla debes cerrar la página y volver a ingresar.",
        "ℹ️ Hay una actualización disponible"
      );
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  font-size: 16px;
}
body {
  background-color: #181818 !important;
  font-family: "Roboto", sans-serif;
}

input,
select {
  font-size: 100%;
}

#menu__BV_toggle_ {
  background-color: #00b7ff;
}

.dropdown-menu.show {
  background-color: #373737;
}

.dropdown-menu.show a {
  color: #fff;
}

.dropdown-menu.show a:hover {
  background-color: rgb(41, 41, 41);
}

.bntv__chat-wrapper {
  padding-left: 1rem;
}

#changePassword___BV_modal_content_ {
  background-color: #373737;
  color: #fff;
}

#changePassword___BV_modal_body_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close {
  color: #fff !important;
  opacity: 1;
}

@media (min-width: 1750px) {
  .container {
    min-width: 1700px !important;
  }
}

@media (min-width: 1140px) and (max-width: 1749px) {
  .container {
    min-width: 1500px !important;
  }
}

@media (max-width: 1570px) {
  .container {
    min-width: 1300px !important;
  }

  .bntv__video-wrapper,
  .bntv__chat-wrapper {
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 1340px) {
  .container {
    min-width: 1100px !important;
  }
}

@media (max-width: 1150px) {
  .container {
    min-width: 900px !important;
  }
  .bntv__video-wrapper,
  .bntv__chat-wrapper {
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (min-width: 769px) and (max-width: 1139px) {
  .container {
    min-width: 665px !important;
    padding: 0px;
  }
  .bntv__video-wrapper,
  .bntv__chat-wrapper {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 768px) {
  .container {
    min-width: 500px !important;
    padding-right: 0;
    padding-left: 0;
  }

  .bntv__chat-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 650px) {
  .container {
    min-width: 370px !important;
    padding-right: 0;
    padding-left: 0;
  }

  .header {
    padding: 0;
    margin-bottom: -30px;
  }
}

@media (max-width: 400px) {
  .container {
    min-width: 300px !important;
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
