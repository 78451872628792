import axios from "axios";

export default {
  async activeEvent() {
    return axios.get(window.localStorage.site + "/api/culto/vivo");
  },
  async updateEvent(payload, event_id) {
    return axios.put(
      window.localStorage.site + "/api/culto/" + event_id,
      payload
    );
  },
};
