<template>
  <div>
    <b-modal
      ref="survey"
      id="survey"
      :title="activeSurvey.title"
      v-model="visible"
      :hide-header-close="!answered"
      :no-close-on-esc="!answered"
      :no-close-on-backdrop="!answered"
    >
      <div class="modal-body">
        <form
          id="surveySubmitForm"
          v-if="activeSurvey.template && !answered"
          v-on:submit.prevent="send()"
        >
          <div
            class="form-group"
            v-for="question in activeSurvey.template.questions"
            :key="question.id"
          >
            <div
              class="boolean-question"
              v-if="question.data_type === 'boolean'"
            >
              <label>{{ question.question }}</label>
              <select
                class="form-control boolean-answer"
                @change="setAnswer(question.id, $event)"
                required
              >
                <option value="">Seleccione una opción</option>
                <option value="SI">SÍ</option>
                <option value="NO">NO</option>
              </select>
            </div>
            <div class="boolean-question" v-else>
              <label>{{ question.question }}</label>
              <textarea
                class="form-control"
                placeholder="Escriba aquí"
                required
                @change="setAnswer(question.id, $event)"
              />
            </div>
          </div>
        </form>
        <div id="success" v-if="activeSurvey.template && answered">
          <label>Gracias por responder el formulario</label>
        </div>
      </div>
      <template #modal-footer="{}">
        <div>
          <b-button
            size="md"
            variant="primary"
            v-if="!answered"
            type="submit"
            form="surveySubmitForm"
          >
            Enviar</b-button
          >
          <a id="changeAnswer" @click="changeAnswer" href="#" v-else>
            Modificar respuesta</a
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      answers: [],
    };
  },
  computed: {
    ...mapGetters({
      activeSurvey: "survey/getActiveSurvey",
      answered: "survey/getAnswered",
    }),
    visible: {
      get: function () {
        return !(Object.keys(this.activeSurvey).length === 0) && this.answered !== null;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  watch: {
    activeSurvey: function () {
      if (this.activeSurvey) {
        this.$store.dispatch("survey/getUserAnswers", this.activeSurvey);
      }
    },
  },
  methods: {
    setAnswer: function (question_id, event) {
      const index = this.answers.findIndex(
        (element) => element.question_id === question_id
      );
      if (index === -1) {
        this.answers.push({
          survey_id: this.activeSurvey.id,
          user_id: this.$root.user.ID,
          question_id: question_id,
          answer: event.target.value,
        });
        return;
      }
      this.answers[index].answer = event.target.value;
    },
    send: function () {
      this.$store.dispatch("survey/saveAnswer", this.answers);
    },
    changeAnswer: function () {
      this.$store.commit("survey/answered", false);
    },
  },
};
</script>
