import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import environment from "../env.js";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSimpleAlert from "vue-simple-alert";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import VueYoutube from "vue-youtube";
import moment from "moment";
import VueToastify from "vue-toastify";
import { version } from "../package.json";

Bugsnag.start({
  apiKey: "75793d69d88d3c7d1b4482d438594b7a",
  plugins: [new BugsnagPluginVue()],
  onError: function (event) {
    try {
      const user = JSON.parse(window.localStorage.user);
      event.setUser(user.ID, user.user_email, user.display_name);
    } catch (error) {
      // do nothing
    }
  },
  appVersion: version,
});

const bugsnagVue = Bugsnag.getPlugin("vue");
bugsnagVue.installVueErrorHandler(Vue);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSimpleAlert);
Vue.use(VueYoutube);
Vue.prototype.moment = moment;
moment.locale("es");
Vue.use(VueToastify, {
  position: "top-center",
  delay: 5000,
  duration: 5000,
  iconEnabled: false,
  defaultTitle: false,
  theme: "light",
});

window.localStorage.site = environment.environment.api_url; // TODO: remove

let deferredPrompt;
window.addEventListener("beforeinstallprompt", (e) => {
  if (window.localStorage.user) return;
  e.preventDefault();
  deferredPrompt = e;
});

window.addEventListener("logedIn", () => {
  if (deferredPrompt) deferredPrompt.prompt();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
