<template>
  <div class="miib-login-background">
    <div class="container amib-login">
      <div class="btn-login">
          <router-link :to="`/login`"> Volver a la página de inicio </router-link>
      </div>
      <div class="row vertical-offset-200">
        <div class="amib-panel-container">
          <div class="panel">
            <div class="panel-heading">
              <p>Crea tu nueva clave</p>
              <div class="clearfix"></div>
            </div>

            <div class="panel-body">
              <form
                accept-charset="UTF-8"
                role="form"
                action="/login"
                method="POST"
                id="login_form"
              >
                <fieldset>
                  <div class="form-group">
                    <input
                      class="form-control"
                      placeholder="email"
                      v-model="email"
                      type="text"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      class="form-control"
                      placeholder="Nueva contraseña"
                      v-model="password"
                      type="password"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      class="form-control"
                      placeholder="Repita su nueva contaseña"
                      v-model="password_confirmation"
                      type="password"
                    />
                  </div>

                  <p class="formError">{{ message }}</p>

                  <div class="form-group" v-if="token">
                    <input
                      v-if="!changed"
                      class="btn btn-sm pull-right"
                      type="button"
                      v-bind:value="btn_action"
                      v-on:click="send"
                    />
                    <router-link v-else to="/login">
                      Ingresar al sitio</router-link
                    >
                  </div>
                  <div v-else>
                    Link inválido,
                    <router-link to="/forgotPassword">
                      Haz click aquí para solicitar otro
                    </router-link>
                  </div>
                  <div id="miit-login-error" class="hidden">
                    <small class="text-danger">{{ error }}</small>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import validations from "../mixins/validations";

export default {
  name: "login",
  data: function () {
    return {
      email: "",
      password: "",
      password_confirmation: "",
      message: "",
      error: "",
      token: "",
      changed: false,
      btn_action: "Guardar",
    };
  },
  mixins: [validations],
  created() {
    this.token = this.$router ? this.$router.currentRoute.query.token : "";
  },
  methods: {
    send: function () {
      // const queryString = window.location.search;
      // const urlParams = new URLSearchParams(queryString);
      // const token = urlParams.get("token");
      if (!this.validEmail(this.email)) {
        return;
      }
      if (!this.validPassword()) {
        return;
      }
      this.btn_action = "cargando...";
      this.error = "";
      this.message = "";
      var link = window.localStorage.site + "/api/reset-password";
      axios
        .post(link, {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.token,
        })
        .then((response) => {
          this.message = response.data;
          this.changed = true;
        })
        .catch(() => {
          this.error =
            "Ha ocurrido un error, por favor contacte a administrador";
          this.btn_action = "Guardar";
        });
    },
  },
};
</script>
<style scoped>
.btn-login{
  margin: 20px;
}
</style>