export default {
  userCanAccessEvent: function (user, event) {
    if (
      eventIsOpen(event) ||
      this.userIsAdmin(user) ||
      userGroupHasAccess(user, event)
    ) {
      return true;
    }

    return false;
  },

  userIsGuest: (user) => {
    return user.invitacion_id != null;
  },

  userIsAdmin: (user) => {
    return user.capabilities == 10;
  },

  userIsMessageAuthor: function (chatMessage, user) {
    if (user.ID && user.ID === chatMessage.user_id) {
      return true;
    }
    if (
      user.invitacion_id &&
      user.invitacion_id === chatMessage.signed_urls_id
    ) {
      return true;
    }
    return false;
  },
};

function userGroupHasAccess(user, event) {
  return user.groups.some((item) => item === event.event_group_id);
}

function eventIsOpen(event) {
  return event.event_group_id === "";
}
