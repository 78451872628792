import axios from "axios";
import environment from "./../../env";
export default {
  async getCapabilities() {
    return axios.get(environment.environment.api_url + "/api/capabilities/9");
  },
  async get() {
    return axios.get(environment.environment.api_url + "/api/user/from-site");
  },
  async addToGroup(payload) {
    return axios.post(
      environment.environment.api_url + "/api/user/add-to-group",
      payload
    );
  },
  async removeFromGroup(payload) {
    return axios.post(
      environment.environment.api_url + "/api/user/remove-from-group",
      payload
    );
  },
  async dropGuests(id = null) {
    return axios.delete(
      id
        ? `${environment.environment.api_url}/api/signed-url/${id}`
        : `${environment.environment.api_url}/api/signed-url`
    );
  },
  async getActiveGuests() {
    return axios.get(`${environment.environment.api_url}/api/signed-url`);
  },
  async resendInvite(id) {
    return axios.post(
      `${environment.environment.api_url}/api/signed_url/${id}/resend`
    );
  },
  async me() {
    return axios.get(`${environment.environment.api_url}/api/me`);
  },
};
