/* eslint-disable no-console */

import { register } from "register-service-worker";
// service worker update 2.11.1

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      window.postMessage({ type: "update-found" }, "*");
    },
    updated(registration) {
      const worker = registration.waiting;
      if (worker) {
        worker.addEventListener("statechange", () => {
          if (worker.state === "activated") {
            window.location.reload();
          }
        });
        worker.postMessage({ action: "SKIP_WAITING" });
      }
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });

  let refreshing = false;
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (!refreshing) {
      window.location.reload();
      refreshing = true;
    }
  });
}
