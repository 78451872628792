<template>
  <div>
    <div class="row">
      <div class="col-12"></div>
    </div>
    <section class="main__content--frames">
      <div class="row d-none d-md-flex">
        <div class="col-7">
          <router-link to="/" class="link">
            <div class="row">
              <img src="/img/logomlac.svg" class="col-4" />
            </div>
          </router-link>
        </div>
        <div class="col-5">
          <a class="back" @click="$router.go(-1)"> Volver </a>
        </div>
      </div>
      <b-button
        class="btnAdd"
        variant="outline-primary"
        @click="openCreateGroup()"
        >Crear grupo</b-button
      >
      <center v-if="loading" class="loading">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </center>
      <div id="group-table" v-else>
        <b-table
          dark
          :items="groups"
          :fields="fields"
          @row-clicked="openEditGroup"
        ></b-table>
        <b-card sub-title="Información" v-if="groups.length == 0">
          <b-card-text>
            Aún no hay grupos creados.
            <a class="card-link" v-b-modal.group>Agrega el primero</a>
          </b-card-text>
        </b-card>
      </div>
      <b-modal
        ref="group"
        id="group"
        v-bind:title="title"
        @ok="submitGroup"
        @cancel="clearData"
      >
        <b-form>
          <b-form-group id="input-group-1" label="Nombre:">
            <b-form-input
              id="name"
              v-model="name"
              maxlength="80"
              type="text"
              placeholder="Escribe el nombre del grupo"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Descripcion:">
            <b-form-input
              id="description"
              v-model="description"
              maxlength="200"
              type="text"
              placeholder="Escribe la descripcion del grupo"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-invalid-feedback :state="validation">
            Debe ingresar nombre y descripción
          </b-form-invalid-feedback>
          <b-button
            variant="outline-danger"
            @click="deleteGroup"
            v-if="group_id"
            >Eliminar grupo</b-button
          >
        </b-form>
      </b-modal>
    </section>
  </div>
</template>
<script>
import groupApi from "../api/groups";
export default {
  data: function () {
    return {
      loading: true,
      name: "",
      description: "",
      group_id: "",
      groups: [],
      fields: [
        "id",
        { key: "name", label: "Nombre del grupo " },
        { key: "description", label: "Descripcion del grupo " },
        { key: "user.display_name", label: "Creado por" },
        { key: "created_at", label: "Fecha creación" },
      ],
    };
  },
  created: function () {
    this.getGroups();
  },
  computed: {
    title: function () {
      if (this.group_id) {
        return "Editar grupo";
      }
      return "Crear grupo";
    },
    validation: function () {
      return this.name.length > 0 && this.description.length > 0;
    },
  },
  methods: {
    openCreateGroup: function () {
      this.clearData();
      this.$refs["group"].show();
    },
    openEditGroup: function (group) {
      this.group_id = group.id;
      this.name = group.name;
      this.description = group.description;
      this.$refs["group"].show();
    },
    submitGroup: function (e) {
      if (!this.validation) {
        e.preventDefault();
        return;
      }
      if (this.group_id) {
        this.updateGroup();
        return;
      }
      this.saveGroup();
    },
    saveGroup: function () {
      groupApi
        .save({
          name: this.name,
          description: this.description,
          created_by: this.$root.user.ID,
        })
        .then(() => {
          this.getGroups();
        });
    },
    updateGroup: function () {
      groupApi
        .update(
          { name: this.name, description: this.description },
          this.group_id
        )
        .then(() => {
          this.getGroups();
        });
    },
    getGroups: function () {
      this.loading = true;
      groupApi
        .get()
        .then((res) => {
          this.groups = res.data;
          this.loading = false;
          this.clearData();
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
    clearData: function () {
      this.group_id = "";
      this.name = "";
      this.description = "";
    },
    deleteGroup: function () {
      groupApi.delete(this.group_id).then(() => {
        this.$refs["group"].hide();
        this.getGroups();
        this.clearData();
      });
    },
  },
};
</script>
<style>
.btn {
  margin-bottom: 10px;
}
.back {
  float: right;
  cursor: pointer;
}
.btnAdd {
  float: right;
}
#group-table tr {
  cursor: pointer;
}
</style>
