export default {
  methods: {
    validEmail: function (email) {
      if (this.email.length == 0) {
        this.error = "Ingrese email";
        return;
      }
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.error = "El correo electrónico debe ser válido.";
      return re.test(email);
    },
    validPassword: function () {
      if (this.password.length < 8) {
        this.error = "La clave debe tener al menos 8 caracteres";
        return false;
      }
      if (this.password_confirmation != this.password) {
        this.error = "Las contraseñas no coinciden";
        return false;
      }
      return true;
    },
  },
};
