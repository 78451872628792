<template>
  <div>
    <b-modal
      ref="invite"
      id="invite"
      title="Enviar una invitación a este culto"
      @hidden="setInvitationValues"
    >
      <div class="modal-body" v-if="guestList.length <= 0">
        <div class="form-group">
          <label for="exampleFormControlSelect2">Nombre</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nombre de la visita"
            v-model="nombre"
            @focus="hideMessageSent"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect2">E-Mail</label>
          <input
            type="email"
            class="form-control"
            placeholder="Email de la visita"
            v-model="email"
            @focus="hideMessageSent"
          />
        </div>
        <div class="form-group">
          <b-form-checkbox v-model="canChat">
            Puede ver/interactuar en el Chat
          </b-form-checkbox>
        </div>
        <b-spinner v-if="loading" label="Loading..."></b-spinner>
      </div>
      <div class="ml-3 mb-4">
        <div class='mb-3'>
          <a 
          href='/formatoInvitaciones.xlsx'
          download
          >      
          <b-button size="md" variant="info"
            >Descargar Formato</b-button>  
          </a>
        </div>
        <input accept=".xlsx, .csv" type="file" variant="success" @change="loadExcel" value="Excel" ref="fileInput"/>
        <b-button size="xs" v-if="guestList.length > 0" @click="clearFile">
          x
        </b-button>        
        <p class='textSupportedFiles'>(Archivos soportados: .xlsx .csv)</p>
        <b-alert class='mt-4' variant="danger" :show="showErrorMessage">{{ errorMessage }}!</b-alert>
        <b-alert variant="primary" :show="messageSent">{{ message }}!</b-alert>        
      </div>
      <template #modal-footer="{ cancel }">
        <b-form-checkbox
          v-model="keepOpen"
          value="accepted"
          unchecked-value="not_accepted"
        >
          Enviar otra
        </b-form-checkbox>
        <b-button size="md" variant="primary" @click="sendInvitationToEveryone()"
          >Enviar Invitación</b-button
        >
        <b-button size="md" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import guests from "@/api/guests";
import * as XLSX from 'xlsx';
import validationsService from "@/services/validations";

export default {
  data: function () {
    return {
      id: "",
      nombre: "",
      email: "",
      canChat: true,
      message: "",
      loading: false,
      messageSent: false,
      keepOpen: "not_accepted",
      guestList: [],
      showErrorMessage: false,
      errorMessage: '',
    };
  },
  methods: {
    clearFile: function () {
        this.$refs.fileInput.value = '';
        this.guestList = [];
    },
    loadExcel: function (event) {
      const file = event.target.files[0];
      if (!file.name.endsWith('.xlsx') || !file.name.endsWith('.xlsx')) {
          this.clearFile();
          this.showErrorMessage = true;
          this.errorMessage = 'El formato no es válido';
          setTimeout(() => {
              this.showErrorMessage = false;
              this.errorMessage = '';
          }, 5000);          
          return;
      }
      
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        this.guestList = jsonData;

        if (headers.length !== 2 || !headers.includes('nombre') || !headers.includes('email')) {
            this.showErrorMessage = true;
            this.errorMessage = 'El formato de las columnas no corresponde';
            this.clearFile();
            setTimeout(() => {
                this.showErrorMessage = false;
                this.errorMessage = '';
            }, 5000);
            return;
        }

        if (jsonData.length === 0) {
            this.showErrorMessage = true;
            this.errorMessage = 'No hay registros en el excel';
            this.clearFile();
            setTimeout(() => {
                this.showErrorMessage = false;
                this.errorMessage = '';
            }, 5000);
            return;
        }
      };

      reader.readAsArrayBuffer(file);
    },
    sendInvitationToEveryone: function (){
      this.sendInvitation();

      if(this.guestList.length > 0){
        this.guestList.forEach(obj => {
          this.nombre = obj.nombre;
          this.email = obj.email;
          this.can_chat = 1;
          this.sendInvitation();
        });
      }
    },
    hideMessageSent: function () {
      this.messageSent = false;
    },
    setInvitationValues: function () {
      this.keepOpen = "not_accepted";
      this.messageSent = false;
    },
    sendInvitation: async function () {
      if (this.nombre.length === 0) {
        this.showErrorMessage = true;
        this.errorMessage = "Ingrese nombre";
        return;
      }

      if (this.email.length === 0) {
        this.showErrorMessage = true;
        this.errorMessage = "Ingrese mail";
        return;
      }

      if(validationsService.validateEmail(this.email) === false){
        this.showErrorMessage = true;
        this.errorMessage = "Ingrese un formato de correo válido";
        return;
      }

      this.loading = true;
      this.showErrorMessage = false;

      try {
        let response = await guests.sendInvitation({
          email: this.email,
          nombre: this.nombre,
          can_chat: this.canChat ? 1 : 0,
          site_id: 9,
        });

        if(await response == "OK"){
          this.message = `Invitación enviada con éxito`;
          if (this.keepOpen === "not_accepted") {
            setTimeout(() => {
              this.$refs["invite"].hide();
            }, 3000);
          }
        } else {
          console.error("ERROR: " + response);
          this.message = "Error al enviar invitación, por favor contacta al administrador";
        }
          this.messageSent = true;
          this.loading = false;
          this.email = "";
          this.nombre = "";
          this.guestList = [];
      } catch (error) {
        this.message = "Ha ocurrido un error, contacte a soporte";
      }
    },
  },
};
</script>

<style>
.textSupportedFiles {
  font-style: italic;
  color: #667
}
</style>
