import axios from "axios";

export default {
  async getTemplates() {
    return axios.get(window.localStorage.site + `/api/survey/template`);
  },
  async saveSurvey(payload) {
    return axios.post(window.localStorage.site + `/api/survey`, payload);
  },
  async getActiveSurvey() {
    return axios.get(window.localStorage.site + `/api/survey/active`);
  },
  async saveAnswer(payload) {
    return axios.post(window.localStorage.site + `/api/survey/answer`, payload);
  },
  async getUserAnswers(survey_id) {
    return axios.get(
      window.localStorage.site + `/api/survey/answer/by-user/` + survey_id
    );
  },
  async deleteSurvey(survey_id) {
    return axios.delete(window.localStorage.site + `/api/survey/` + survey_id);
  },
};
