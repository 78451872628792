export function getSource(url) {
  if (url) {
    let video = parseVideoURL(url);
    return getEmbedVideoURL(video);
  }
  return "";
}

export function validateLink(url) {
  if (getSource(url)) {
    return true;
  }
  return false;
}

export function parseVideoURL(url) {
  let video = {};
  video.id = getYoutubeId(url) || getVimeoId(url) || getMuxId(url);

  if (video.id) {
    video.type = getYoutubeId(url)
      ? "youtube"
      : getVimeoId(url)
        ? "vimeo"
        : "mux";
  }

  return video;
}

function getYoutubeId(url) {
  let id = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    // eslint-disable-next-line no-useless-escape
    id = url[2].split(/[^0-9a-z_\-]/i);
    id = id[0];

    return id;
  }

  return false;
}

function getVimeoId(url) {
  url = url
    .replace(/(>|<)/gi, "")
    .split(
      /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/
    );
  if (url[5] !== undefined) {
    return url[5];
  }
  return false;
}

function getMuxId(url) {
  const regex = /\/(\w+)\.m3u8$/;
  const match = url.match(regex);

  if (match[1] !== undefined) {
    return match[1];
  }
  return false;
}

function getEmbedVideoURL(video) {
  if (video.type === "youtube") {
    return `https://www.youtube.com/embed/${video.id}`;
  }

  if (video.type === "vimeo") {
    return `https://player.vimeo.com/video/${video.id}`;
  }

  return false;
}
