import usersApi from "../api/user";

export default {
  methods: {
    dropGuests: function (id = null, callback = null) {
      let self = this;
      let infoMessage =
        id === null
          ? "¿Quieres caducar todas las invitaciones?"
          : "¿Quieres caducar esta invitación?";

      let confirmation =
        id === null
          ? "Se caducaron todas las invitaciones"
          : "Se caducó la invitación";

      this.$confirm(infoMessage).then((confirm) => {
        if (confirm) {
          usersApi
            .dropGuests(id)
            .then(() => {
              console.debug(`socket.emit: dropped Guest with id: ${id}`);
              self.$root.socket.emit("disconnect-guests", id);
              if (callback) {
                callback();
              }
              this.$alert(confirmation, "¡Hecho!");
            })
            .catch((err) => {
              this.$alert(
                `No se pudo caducar las invitaciones (${err})`,
                "¡Error!"
              );
            });
        }
      });
    },
  },
};
