import moment from "moment";

export default {
  createHash() {
    return "" + Math.floor(Math.random() * 10000) + Date.now();
  },
  isCurrentDate(someDate, currentDate = null) {
    const date = currentDate
      ? moment(currentDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    const parsedDate = moment(someDate).format("YYYY-MM-DD");
    return moment(date).isSame(parsedDate);
  },
  autoScroll(container) {
    const position = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    if (scrollHeight - position < 600) {
      setTimeout(function () {
        container.scrollTop = 10000000;
      }, 100);
    }
  },
  copyToClipboard(text) {
    return navigator.clipboard.writeText(text);
  },
};
