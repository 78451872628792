import emoji from "emoji.json";

export default {
  getFrecuentlyUsedEmojis: () => {
    if (!localStorage.getItem("favoritos")) {
      return [];
    }
    return JSON.parse(localStorage.getItem("favoritos"));
  },
  insertEmoji: (emoji, message) => {
    return `${message} ${emoji.char}`;
  },
  addToBookmarks: (bookmarks, emoji) => {
    if (bookmarks.indexOf(emoji) == -1) {
      bookmarks.push(emoji);
      localStorage.setItem("favoritos", JSON.stringify(bookmarks));
    }
  },
  filteredEmojis: function () {
    return emoji.filter(emoji => {
      return emoji.char !== '🤬' && emoji.char !== '😈'
        && emoji.char !== '👿' && emoji.char !== '💀'
        && emoji.char !== '☠️' && emoji.char !== '☠'
        && emoji.char !== '🤟' && emoji.char !== '🤟🏻'
        && emoji.char !== '🤟🏼' && emoji.char !== '🤟🏽'
        && emoji.char !== '🤟🏾' && emoji.char !== '🤟🏿'
        && emoji.char !== '🤘' && emoji.char !== '🤘🏻'
        && emoji.char !== '🤘🏼' && emoji.char !== '🤘🏽'
        && emoji.char !== '🤘🏾' && emoji.char !== '🤘🏿'
        && emoji.char !== '🖕' && emoji.char !== '🖕🏻'
        && emoji.char !== '🖕🏼' && emoji.char !== '🖕🏽'
        && emoji.char !== '🖕🏾' && emoji.char !== '🖕🏿'
        && emoji.char !== '👩🏽‍🤝‍👨🏾' && emoji.char !== '👩🏾‍🤝‍👨🏿'
        && emoji.char !== '👬' && emoji.char !== '👬🏻'
        && emoji.char !== '👨🏻‍🤝‍👨🏼' && emoji.char !== '👨🏻‍🤝‍👨🏽'
        && emoji.char !== '👨🏻‍🤝‍👨🏾' && emoji.char !== '👨🏻‍🤝‍👨🏿'
        && emoji.char !== '👨🏼‍🤝‍👨🏻' && emoji.char !== '👬🏼'
        && emoji.char !== '👨🏼‍🤝‍👨🏽' && emoji.char !== '👨🏼‍🤝‍👨🏾'
        && emoji.char !== '👨🏼‍🤝‍👨🏿' && emoji.char !== '👨🏽‍🤝‍👨🏻'
        && emoji.char !== '👨🏽‍🤝‍👨🏼' && emoji.char !== '👬🏽'
        && emoji.char !== '👨🏽‍🤝‍👨🏾' && emoji.char !== '👨🏽‍🤝‍👨🏿'
        && emoji.char !== '👨🏾‍🤝‍👨🏻' && emoji.char !== '👨🏾‍🤝‍👨🏼'
        && emoji.char !== '👨🏾‍🤝‍👨🏽' && emoji.char !== '👨🏾‍🤝‍👨🏻'
        && emoji.char !== '👨🏾‍🤝‍👨🏼' && emoji.char !== '👨🏾‍🤝‍👨🏽'
        && emoji.char !== '👬🏾' && emoji.char !== '👨🏾‍🤝‍👨🏿'
        && emoji.char !== '👨🏿‍🤝‍👨🏻' && emoji.char !== '👨🏿‍🤝‍👨🏼'
        && emoji.char !== '👨🏿‍🤝‍👨🏽' && emoji.char !== '👨🏿‍🤝‍👨🏾'
        && emoji.char !== '👬🏿' && emoji.char !== '💏'
        && emoji.char !== '👨‍❤️‍💋‍👨' && emoji.char !== '👨‍❤‍💋‍👨'
        && emoji.char !== '👩‍❤️‍💋‍👩' && emoji.char !== '👩‍❤‍💋‍👩'
        && emoji.char !== '💑' && emoji.char !== '👨‍❤️‍👨'
        && emoji.char !== '👨‍❤‍👨' && emoji.char !== '👩‍❤️‍👩'
        && emoji.char !== '👩‍❤‍👩' && emoji.char !== '👪'
        && emoji.char !== '👨‍👨‍👦' && emoji.char !== '👨‍👨‍👧'
        && emoji.char !== '👨‍👨‍👧‍👦' && emoji.char !== '👨‍👨‍👦‍👦'
        && emoji.char !== '👨‍👨‍👧‍👧' && emoji.char !== '👩‍👩‍👦'
        && emoji.char !== '👩‍👩‍👧' && emoji.char !== '👩‍👩‍👧‍👦'
        && emoji.char !== '👩‍👩‍👦‍👦' && emoji.char !== '👩‍👩‍👧‍👧'
        && emoji.char !== '🎄' && emoji.char !== '🏳️‍🌈'
        && emoji.char !== '🏳‍🌈' && emoji.char !== '🧑‍🤝‍🧑'
        && emoji.char !== '🧑🏻‍🤝‍🧑🏻' && emoji.char !== '🧑🏻‍🤝‍🧑🏼'
        && emoji.char !== '🧑🏻‍🤝‍🧑🏽' && emoji.char !== '🧑🏻‍🤝‍🧑🏾'
        && emoji.char !== '🧑🏽‍🤝‍🧑🏾' && emoji.char !== '🧑🏽‍🤝‍🧑🏽'
        && emoji.char !== '🧑🏽‍🤝‍🧑🏼' && emoji.char !== '🧑🏽‍🤝‍🧑🏻'
        && emoji.char !== '🧑🏼‍🤝‍🧑🏿' && emoji.char !== '🧑🏼‍🤝‍🧑🏾'
        && emoji.char !== '🧑🏼‍🤝‍🧑🏽' && emoji.char !== '🧑🏼‍🤝‍🧑🏼'
        && emoji.char !== '🧑🏼‍🤝‍🧑🏻' && emoji.char !== '🧑🏻‍🤝‍🧑🏿'
        && emoji.char !== '🧑🏽‍🤝‍🧑🏿' && emoji.char !== '🧑🏾‍🤝‍🧑🏻'
        && emoji.char !== '🧑🏾‍🤝‍🧑🏼' && emoji.char !== '🧑🏾‍🤝‍🧑🏽'
        && emoji.char !== '🧑🏾‍🤝‍🧑🏾' && emoji.char !== '🧑🏾‍🤝‍🧑🏿'
        && emoji.char !== '🧑🏿‍🤝‍🧑🏻' && emoji.char !== '🧑🏿‍🤝‍🧑🏼'
        && emoji.char !== '🧑🏿‍🤝‍🧑🏽' && emoji.char !== '🧑🏿‍🤝‍🧑🏾'
        && emoji.char !== '🧑🏿‍🤝‍🧑🏿' && emoji.char !== '👭'
        && emoji.char !== '👭🏻' && emoji.char !== '👩🏻‍🤝‍👩🏼'
        && emoji.char !== '👩🏻‍🤝‍👩🏽' && emoji.char !== '👩🏻‍🤝‍👩🏾'
        && emoji.char !== '👩🏻‍🤝‍👩🏿' && emoji.char !== '👩🏼‍🤝‍👩🏻'
        && emoji.char !== '👭🏼' && emoji.char !== '👩🏼‍🤝‍👩🏽'
        && emoji.char !== '👩🏾‍🤝‍👩🏽' && emoji.char !== '👩🏾‍🤝‍👩🏼'
        && emoji.char !== '👩🏾‍🤝‍👩🏻' && emoji.char !== '👩🏽‍🤝‍👩🏿'
        && emoji.char !== '👩🏽‍🤝‍👩🏾' && emoji.char !== '👭🏽'
        && emoji.char !== '👩🏽‍🤝‍👩🏼' && emoji.char !== '👩🏽‍🤝‍👩🏻'
        && emoji.char !== '👩🏼‍🤝‍👩🏿' && emoji.char !== '👩🏼‍🤝‍👩🏾'
        && emoji.char !== '👭🏾' && emoji.char !== '👩🏾‍🤝‍👩🏿'
        && emoji.char !== '👩🏿‍🤝‍👩🏻' && emoji.char !== '👩🏿‍🤝‍👩🏼'
        && emoji.char !== '👩🏿‍🤝‍👩🏽' && emoji.char !== '👩🏿‍🤝‍👩🏾'
        && emoji.char !== '👭🏿' && emoji.char !== '👯'
        && emoji.char !== '👯‍♂️' && emoji.char !== '👯‍♂'
        && emoji.char !== '👯‍♀️' && emoji.char !== '👯‍♀'
    });
  }
};