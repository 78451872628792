import Event from "../api/events";

export default {
  namespaced: true,
  state: {
    activeEvent: {},
  },
  mutations: {
    activeEvent(state, data) {
      if (data.id && Object.keys(data).length !== 0) {
        data.event_group_id = data.event_group_id || "";
      }
      state.activeEvent = data;
    },
  },
  actions: {
    getActiveEvent({ commit }) {
      return new Promise((resolve, reject) => {
        Event.activeEvent().then(
          (res) => {
            if (res.data.length) {
              commit("activeEvent", res.data[0]);
            } else {
              commit("activeEvent", {});
            }
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    updateEvent({ getters }, payload) {
      return new Promise((resolve, reject) => {
        Event.updateEvent(payload, getters.getActiveEvent.id).then(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
  },
  getters: {
    getActiveEvent(state) {
      return state.activeEvent;
    },
  },
};
