import axios from "axios";

export default {
  async get() {
    return axios.get(window.localStorage.site + `/api/group`);
  },
  async save(payload) {
    return axios.post(window.localStorage.site + `/api/group`, payload);
  },
  async update(payload, group_id) {
    return axios.put(
      window.localStorage.site + `/api/group/` + group_id,
      payload
    );
  },
  async delete(group_id) {
    return axios.delete(window.localStorage.site + `/api/group/` + group_id);
  },

  async byUser(user) {
    return axios.get(window.localStorage.site + "/api/group/by-user/" + user);
  },
};
