<template>
  <div>
    <div id="chat">
      <div class="title">
        <h3>Chat</h3>
        <span 
          v-if="userIsAdmin" 
          class="status-indicator" 
          :class="{ 'connected': isConnected, 'disconnected': !isConnected }"
        />
      </div>
      <div id="mensajes" v-show="mostrarMensajes">
        <div v-if="loading" class="loading justify-content-center text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Cargando...</span>
          </div>
        </div>

        <div v-else-if="chats.length == 0">
          <p class="text-center no-chats">No hay mensajes aún</p>
        </div>

        <div v-for="chat in chats" v-bind:key="chat.id">
          <div class="mensaje">
            <img v-if="chat.imageURL" class="user-img" :src="chat.imageURL" />
            <div
              v-else
              class="user-img"
              v-bind:style="{ backgroundColor: chat.userColor }"
            >
              <span v-if="chat.userInital" class="user-initial">{{
                chat.userInital
              }}</span>
            </div>
            <div class="texto">
              <strong class="display-name"> {{ chat.display_name }} </strong>
              <span class="text-muted">{{ chat.created_at | humanDate }}</span>
              <p class="chat-msg">
                {{ chat.message }}
                <a
                  href="#"
                  v-if="shouldShowDeleteButton(chat)"
                  @click="deleteMessage(chat)"
                  class="clickeable"
                >
                  <img src="../../public/img/trash.svg" class="trash" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-bottom-left-block">
        <div class="fast-messages">
          <h6>Mensajes rápidos:</h6>
          <div class="fast-message" v-for="message in mensajesRapidos" :key="message">
            <button class="fast-message-button" @click="fastMessage(message)">
              {{ message }}
            </button>
          </div>
        </div>
      </div>
      <div id="emojis-open" v-show="shouldShowEmojiSelector">
        <div v-if="favoritos.length > 0">
          <small class="emoji-section">Favoritos</small>
          <div class="emoji-group" id="favoritos">
            <div
              class="emo"
              v-for="emoji in favoritos"
              @click="insertEmoji(emoji)"
              v-bind:key="emoji.id"
            >
              {{ emoji.char }}
            </div>
          </div>
        </div>
        <div>
          <small class="emoji-section">Todos</small>
          <div class="emoji-group all">
            <div
              class="emo"
              v-for="emoji in emojis"
              @click="insertEmoji(emoji)"
              v-bind:key="emoji.id"
            >
              {{ emoji.char }}
            </div>
          </div>
        </div>
      </div>
      <div id="chat-footer" class="row mx-0">
        <div id="user-chat-avatar" class="d-none d-md-flex text-left">
          <img v-if="getUserImageUrl" class="user-img" :src="getUserImageUrl" />
          <div v-else class="user-img" v-bind:style="{ backgroundColor: getUserColor }">
            <span class="user-initial">{{ getUserInitial }}</span>
          </div>
        </div>
        <div class="col mx-1 form-mensaje">
          <div class="msg-field">
            <textarea
              type="text"
              maxlength="500"
              id="chat-texto"
              placeholder="Escribe un mensaje..."
              ref="chatTexto"
              class="col-11"
              v-model="mensaje"
              v-on:keyup.enter="submit"
            ></textarea>
            <img @click="muestraEmojis" class="emoji-click" src="/img/emoji-face.svg" />
            <img class="airplane" @click="submit" src="/img/send.svg" />
          </div>
        </div>
      </div>
      <div class="chat-bottom">
        <div class="chat-bottom-right-block">
          <small class="counter"> {{ this.mensaje.length }} / 500 </small>
          <a href="#" id="chat-enviar" @click="submit">
            <button class="send">Enviar</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emojisService from "@/services/emojis";
import chatService from "@/services/chat";
import { mapGetters } from "vuex";
import moment from "moment";
import userService from "@/services/user";
import utils from "@/utils";
var randomColor = require("randomcolor"); // import the script

export default {
  data: function () {
    return {
      loading: true,
      mensaje: "",
      chats: [],
      emojis: [],
      favoritos: [],
      shouldShowEmojiSelector: false,
      enviando: false,
      ancho: "width: 443px;",
      mensajesRapidos: ["Amén", "Aleluya!", "Gracias Jesús!"],
      mostrarMensajes: true, // mobile
      isConnected: false,
    };
  },

  created: function () {
    this.emojis = emojisService.filteredEmojis();
    console.debug("socket.emit: Retrieving conversation...");
    this.$root.socket.emit("get-chat-history");
    console.debug("socket.on: Loading Chat...");
    this.$root.socket.on("load-chat", (chatHistory) => {
      this.loadChatList(chatHistory);
    });

    this.$root.socket.on("connect_error", () => {
      console.error("Conexión con ERROR!");
      this.isConnected = false;
    });

    this.$root.socket.on("connect", () => {
      console.info("Conexión ESTABLECIDA!");
      this.isConnected = true;
    });

    console.debug("socket.on: New message...");
    this.$root.socket.on("chat", (data) => {
      chatService.pushMessageIntoMessageList(this.chats, data);
      this.autoScroll();
    });

    console.debug("socket.on: Delete message...");
    this.$root.socket.on("delete", (data) => {
      chatService.removeMessage(this.chats, data);
    });

    console.debug("socket.on: User disconnected...");
    this.$root.socket.on("disconnect", () => {
      console.log("conexion perdida!");
    });
  },

  mounted: function () {
    this.loadChatList();
    this.getFrecuentlyUsedEmojis();
    this.updateUserColor();
    this.checkConnection();    
  },

  computed: {
    ...mapGetters({
      event: "events/getActiveEvent",
    }),

    getUserImageUrl: function () {
      if (!this.$root.user.images) return null;
      if (!this.$root.user.images.length) return null;
      const latestImage = this.$root.user.images[this.$root.user.images.length - 1];
      const filename = latestImage.filename;
      if (!filename.match(/\.(jpg|jpeg|png)$/)) return null;
      return `https://d3sl5ozezaww6i.cloudfront.net/${filename}`;
    },

    getUserColor: function () {
      const viewer = this.$root.user;
      const keyLS = localStorage.getItem("user") ? "user" : "invitado";

      if (!viewer.color) {
        viewer.color = randomColor({
          luminosity: "dark",
          hue: "random",
        });

        localStorage.setItem(keyLS, JSON.stringify(viewer));
      }

      return viewer.color;
    },

    getUserInitial() {
      if (this.$root.user.display_name) {
        return this.$root.user.display_name.charAt(0).toUpperCase();
      } else {
        return null;
      }
    },
    userIsAdmin: function () {
      return userService.userIsAdmin(this.$root);
    },
  },

  methods: {
    submit: function () {
      if (this.mensaje.length > 0 && !this.enviando) {
        this.enviando = true;
        const newMessage = chatService.addNewMessage(
          this.chats,
          this.$root.user,
          this.event,
          this.mensaje,
          this.getUserImageUrl,
          this.getUserColor,
          this.getUserInitial
        );
        this.enviando = false;
        this.autoScroll();
        console.debug("socket.emit: New message...");
        this.$root.socket.emit("chat", newMessage);
        this.shouldShowEmojiSelector = false;
        this.mensaje = "";
      }
    },

    deleteMessage: function (chat) {
      chatService.removeMessage(this.chats, chat);
      console.debug("socket.emit: Delete message...");
      this.$root.socket.emit("delete", chat);
    },

    addNewMessage: function (data) {
      this.enviando = false;
      if (!this.chats.some((item) => item.hash === data.hash)) {
        this.chats.push(data);
      }
      this.scroll();
    },

    removeMessage: function (chat) {
      this.chats.map((item) => {
        if (item.hash === chat.hash) {
          item.message = "*Este mensaje fue eliminado*";
          item.status = 0;
        }
      });
    },

    muestraEmojis: function () {
      this.shouldShowEmojiSelector = !this.shouldShowEmojiSelector;
    },

    loadChatList: function (chatHistory) {
      chatHistory = chatHistory || [];
      this.chats = chatHistory;
      this.loading = false;
      this.autoScroll();
    },

    scroll: function () {
      var container = document.getElementById("mensajes");
      var position = document.getElementById("mensajes").scrollTop;
      var scrollHeight = document.getElementById("mensajes").scrollHeight;
      if (scrollHeight - position < 600) {
        setTimeout(function () {
          container.scrollTop = 10000000;
        }, 100);
      }
    },
    shouldShowDeleteButton: function (chat) {
      return chatService.shouldShowDeleteButton(chat, this.$root.user);
    },


    userIsMessageAuthor: function (chat) {
      return userService.userIsMessageAuthor(chat, this.$root.user);
    },

    insertEmoji: function (emoji) {
      this.mensaje = emojisService.insertEmoji(emoji, this.mensaje);
      this.$refs.chatTexto.focus();
      emojisService.addToBookmarks(this.favoritos, emoji);
    },

    toggleEmojiSelector: function () {
      this.shouldShowEmojiSelector = !this.shouldShowEmojiSelector;
    },

    getFrecuentlyUsedEmojis: function () {
      this.favoritos = emojisService.getFrecuentlyUsedEmojis();
    },

    autoScroll: function () {
      const container = document.getElementById("mensajes");
      if (container) {
        utils.autoScroll(container);
      }
    },
    fastMessage: function (message) {
      this.mensaje = message;
      this.submit();
    },
    updateUserColor() {
      let viewer = this.$root.user;
      let keyLS;
      const user = localStorage.getItem("user");
      const guest = localStorage.getItem("invitado");

      if (user) {
        keyLS = "user";
      } else if (guest) {
        keyLS = "invitado";
      }

      let color = viewer.color;
      if (!color) {
        color = randomColor({
          luminosity: "dark",
          hue: "random",
        });
        viewer.color = color;
        localStorage.setItem(keyLS, JSON.stringify(viewer));
      }
    },
    checkConnection: function () {
      if(this.$root.socket.connected === true) return this.isConnected = true;
    },
  },

  filters: {
    humanDate: function (value) {
      return moment.unix(value).startOf("minute").fromNow();
    },
  },
};
</script>

<style scoped>
h3 {
  display: inline-block;
  vertical-align: middle;
}

.status-indicator {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-left: 0.5em;
  vertical-align: middle;
  margin-top: -4px;
}

.connected {
  background-color: green;
}

.disconnected {
  background-color: red;
}

#chat {
  position: absolute;
  background-color: #181818;
  font-size: 13px;
  padding: 10px 5px 10px 0;
  border: 1px solid #3c3c3c;
  border-radius: 5px;
  width: 100%;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #3c3c3c !important;
  background-color: #3c3c3c !important;
}

#chat .title {
  padding-left: 15px;
  color: #fff;
}

#mensajes {
  height: 570px;
  padding: 30px 15px 0px 15px;
  background: #181818;
  color: #fff;
  overflow: auto;
}

.interaction-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}

.mensaje {
  width: 100%;
  color: #fff;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.mensaje strong {
  font-family: sans-serif;
  color: #00b7ff;
  font-weight: bold;
  font-size: 13px;
}

.user-img {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-msg {
  color: #ffffff;
  max-width: 500px;
  word-break: break-word;
}

.trash {
  width: 15px;
  margin: 0px 0px 3px 5px;
  cursor: pointer;
}

.mensaje .text-muted {
  color: #b3b3b3 !important;
}

.send-button a img,
#emojis a img {
  opacity: 0.8;
}

.send-button a:hover img,
#emojis a:hover img {
  opacity: 0.8;
}

.send-button a:active img,
#emojis a:active img {
  opacity: 1;
}

.chat-hora {
  color: #89898a;
}

#user-chat-avatar {
  margin: 4px 0px 0px 10px;
}

.form-mensaje {
  border-radius: 5px;
  background-color: #3d3d3d;
}

.carcter-count {
  margin: 0 0 0 10px;
}

.form-mensaje a {
  color: black;
  font-size: 30px;
  height: 40px;
}

.send-button {
  height: 40px;
}

.emoji-section {
  padding: 5px;
  color: rgb(255, 255, 255);
}

#chat-footer {
  padding-top: 10px;
  padding-bottom: 8px;
}

#favoritos {
  max-height: 60px;
  overflow: auto;
}

#emojis-open {
  position: absolute;
  display: block;
  height: 150px;
  background-color: #3d3d3d;
  color: rgb(255, 255, 255);
  overflow: auto;
  padding: 10px;
  border-radius: 5px;
  box-sizing: content-box;
  width: 95%;
  bottom: 20vh;
}

/* Delete emojis scrollbar */
#emojis-open::-webkit-scrollbar-thumb {
  background-color: rgb(71, 71, 71);
}

.emo {
  float: left;
  padding: 5px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 30px;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.msg-field {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

textarea {
  background-color: #3d3d3d;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #ffffff;
  outline: none;
  align-items: center;
  padding: 15px 0px 0px 0px;
  width: 100%;
  resize: none;
  height: 50px;
  border-radius: 5px;
  max-height: 330px;
}

textarea::placeholder {
  color: #838383;
}

.airplane {
  display: none;
}

.input-field-mobile-devices {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  border: 1px solid #858585;
  border-radius: 5px;
}

.chat-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.chat-bottom-right-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 5px;
}

.chat-bottom-left-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #3c3c3c;
  padding: 10px;
}

.fast-messages {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.fast-messages::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.fast-messages h6 {
  margin: 0px 3px 0px 0px;
  color: #fff;
}

.fast-message-button {
  flex: 0 0 auto;
  border: 1px solid #2d92cb;
  border-radius: 5px;
  padding: 5px 7px;
  margin: 0px 3px;
  color: #2d92cb;
  background-color: transparent;
}

.fast-message-button:hover {
  background-color: #2d92cb;
  color: #fff;
}

.fast-messages a:hover {
  color: #fff;
}

.emoji-click {
  cursor: pointer;
  width: 30px;
}

.counter {
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  color: #b3b3b3 !important;
  font-size: 12px;
}

.send {
  background-color: #2d92cb;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.user-initial {
  font-size: 20px;
  color: #fff;
}

@media (max-width: 1570px) {
  #mensajes {
    height: 550px;
  }

  .chat-msg {
    max-width: 90%;
  }
}

@media (max-height: 900px) {
  #mensajes {
    height: 300px;
  }
}

@media (max-width: 768px) {
  #emojis-open {
    bottom: 13vh;
  }

  #mensajes {
    height: 29vh;
  }

  .user-img {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
  }

  .chat-msg,
  .display-name {
    font-size: 14px;
  }

  .text-muted {
    font-size: 11px;
  }

  .chat-bottom-left-block,
  .fast-messages {
    display: none;
  }

  textarea {
    padding-top: 13px;
  }
}

@media (max-width: 500px) {
  .send {
    display: none;
  }

  .airplane {
    display: block;
  }
  .emoji-click {
    display: none;
  }

  textarea {
    padding-top: 14px;
  }

  #chat-footer {
    padding-left: 5px;
  }
}
</style>
