<template>
  <div class="miib-login-background">
    <div class="container amib-login">
      <div class="btn-login">
          <router-link :to="`/login`"> Volver a la página de inicio </router-link>
      </div>    
      <div class="row vertical-offset-200">
        <div class="amib-panel-container">
          <img src="../../public/img/logo.svg" />
          <div class="panel">
            <div class="panel-heading">
              <p>
                Ingrese su correo y le enviaremos un enlace de recuperación.
              </p>
              <div class="clearfix"></div>
            </div>
            <div class="panel-body">
              <form
                accept-charset="UTF-8"
                role="form"
                method="POST"
                id="login_form"
                v-on:submit.prevent="send"
              >
                <fieldset>
                  <div class="form-group">
                    <input
                      class="form-control"
                      placeholder="Email"
                      name="email"
                      v-model="email"
                      type="text"
                    />
                  </div>
                  <p class="formError">{{ mensaje }}</p>
                  <div class="form-group">
                    <input
                      class="btn btn-sm pull-right"
                      type="submit"
                      v-bind:value="btn_action"
                    />
                  </div>
                  <div id="miit-login-error" class="hidden">
                    <small class="text-danger">{{ error }}</small>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import validations from "../mixins/validations";

export default {
  name: "login",
  data: function () {
    return {
      email: "",
      mensaje: "",
      error: "",
      btn_action: "Recuperar",
      loading: false,
    };
  },
  mixins: [validations],
  methods: {
    send: function () {
      if (this.loading) {
        return;
      }
      if (!this.validEmail(this.email)) {
        return;
      }
      this.loading = true;
      this.btn_action = "cargando...";
      this.error = "";
      this.mensaje = "";
      var link = window.localStorage.site + "/api/forgot-password";
      axios
        .post(link, {
          email: this.email,
        })
        .then((response) => {
          this.mensaje = response.data;
          this.btn_action = "Volver a enviar";
          this.loading = false;
        })
        .catch(() => {
          this.error =
            "Ha ocurrido un error, por favor contacte a administrador";
          this.btn_action = "Recuperar";
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.btn-login{
  margin: 20px;
}
.amib-panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.amib-panel-container img {
  width: 80px;
  margin-bottom: 40px;
}
</style>
