import Survey from "../api/survey";

export default {
  namespaced: true,
  state: {
    templates: [],
    activeSurvey: {},
    answered: null,
    showSurveyEnablement: false,
  },
  mutations: {
    templates(state, data) {
      state.templates = data;
    },
    activeSurvey(state, data) {
      state.activeSurvey = data;
    },
    answered(state, value) {
      state.answered = value;
    },
    showSurveyEnablement(state, value) {
      state.showSurveyEnablement = value;
    },    
  },
  actions: {
    setShowSurveyEnablement ({ commit }, value) {
      commit("showSurveyEnablement", value);
    },
    getActiveSurvey({ commit }) {
      return new Promise((resolve, reject) => {
        Survey.getActiveSurvey().then(
          (res) => {
            commit("activeSurvey", res.data);
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getUserAnswers({ commit }, activeSurvey) {
      Survey.getUserAnswers(activeSurvey.id).then((res) => {
        if (res.data.length !== activeSurvey.template.questions.length) {
          commit("answered", false);
        } else {
          commit("answered", true);
        }
      });
    },
    saveAnswer({ commit }, payload) {
      Survey.saveAnswer(payload).then(commit("answered", true));
    },
    getTemplates({ commit }) {
      Survey.getTemplates().then((res) => {
        commit("templates", res.data);
      });
    },
  },
  getters: {
    getTemplates(state) {
      return state.templates;
    },
    getActiveSurvey(state) {
      return state.activeSurvey;
    },
    getAnswered(state) {
      return state.answered;
    },
    showSurveyEnablement(state){
      return state.showSurveyEnablement;
    }
  },
};
