<template>
  <div>
    <b-modal
      ref="manage-invites"
      id="manage-invites"
      size="xl"
      :title="title"
      scrollable
      cancel-disabled
    >
      <div class="col-2 mb-2">
        <label for="currentDate">Fecha de Transmisión: </label>
        <input
          class="form-control"
          id="currentDate"
          type="date"
          v-model="currentDate"
        />
      </div>
      <p v-if="loadingGuests">Cargando...</p>
      <b-table
        v-else
        :items="filteredGuests"
        :fields="fields"
        sort-by="name"
        sort-icon-left
        striped
        hover
        small
        show-empty
      >
        <template #cell(link_utilizado)="data">
          <span v-if="data.item.guest_ip">SI</span>
          <span v-else>NO</span>
        </template>

        <template #cell(reenviar)="data">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="resendInvite(data.item.id)"
          >
            Reenviar
          </b-button>
        </template>
        <template #cell(estado)="data">
          <b-icon-plug-fill v-if="userIsConnected(data.item.id)"></b-icon-plug-fill>
        </template>
        <template #cell(copiar_link)="data">
          <b-icon-clipboard
            class="cursor-pointer"
            @click="copyToClipboard(data.item)"
          ></b-icon-clipboard>
        </template>
        <template #cell(chat)="data">
          <b-button size="sm" class="btn btn-danger" @click="toggleGuestChat(data.item)">
            {{ data.item.can_chat ? "Desactivar Chat" : "Activar Chat" }}
          </b-button>
        </template>
        <template #cell(desconectar)="data">
          <b-button
            size="sm"
            class="btn btn-danger"
            v-on:click="dropGuests(data.item.id, getActiveGuests)"
          >
            Revocar
          </b-button>
        </template>
        <template #empty> No hay invitados activos. </template>
      </b-table>
      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="primary" @click="ok()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import guests from "../mixins/guests";
import guestsApi from "../api/guests";
import utils from "@/utils";
import userApi from "@/api/user";
import moment from "moment";
import { mapGetters } from "vuex";
import { BIcon, BIconPlugFill, BToast } from "bootstrap-vue";

export default {
  data: function () {
    return {
      loadingGuests: true,
      title: "Invitados Activos",
      components: {
        BIcon,
        BIconPlugFill,
      },
      guests: [],
      fields: [
        { key: "id", sortable: true },
        { key: "nombre", sortable: true },
        { key: "email", sortable: true },
        "link_utilizado",
        "reenviar",
        "copiar_link",
        "estado",
        "chat",
        "desconectar",
      ],
      currentDate: moment().format("YYYY-MM-DD"),
    };
  },
  mixins: [guests],
  computed: {
    ...mapGetters({
      connections: "connections/getConnections",
    }),
    filteredGuests: function () {
      return this.guests.length > 0
        ? this.guests
            .filter((item) =>
              utils.isCurrentDate(moment(item.created_at), this.currentDate)
            )
            .map((item) => {
              if (!item.guest_ip) {
                item._rowVariant = "danger";
              }
              return item;
            })
      : this.guests;
    },
  },
  mounted: async function () {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId === "manage-invites") {
        this.getActiveGuests();
      }
    });
  },
  methods: {
    getActiveGuests: function () {
      this.loadingGuests = this.guests.length < 1;
      guestsApi
        .getActiveGuests()
        .then((r) => {
          this.guests = r.data;
        })
        .finally(() => {
          this.loadingGuests = false;
        });
    },
    toggleGuestChat: function (guest) {
      const action = guest.can_chat ? "deshabilitar" : "habilitar";
      this.$confirm(`¿Quieres ${action} el chat para ${guest.nombre}?`).then(
        (confirm) => {
          if (!confirm) return;
          const data = { ...guest, can_chat: guest.can_chat ? 0 : 1 };
          guestsApi
            .toggleGuestChat(data)
            .then(() => {
              console.debug("socket.emit: Toggle Guest Chat");
              this.$root.socket.emit("update-user-status", data);
            })
            .finally(() => {
              this.getActiveGuests();
            });
        }
      );
    },
    userIsConnected: function (id) {
      return this.connections.filter((item) => item.id == id).length > 0;
    },
    copyToClipboard: async (guest) => {
      const link = `${window.location.origin}/?token=${guest.token}`;
      await utils.copyToClipboard(link);
      let toast = new BToast();
      toast.$bvToast.toast(`Link copiado al portapapeles`, {
        title: "Link copiado",
        autoHideDelay: 1000,
        appendToast: true,
        variant: "info",
      });
    },
    resendInvite: function (id) {
      userApi.resendInvite(id).then(() => {
        let toast = new BToast();
        toast.$bvToast.toast(`El correo de invitación ha sido reenviado a la visita`, {
          title: "Link reenviado",
          autoHideDelay: 1000,
          appendToast: true,
          variant: "info",
        });
      });
    },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
