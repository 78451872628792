import Vue from "vue";
import Vuex from "vuex";
import events from "./events";
import connections from "./connections";
import survey from "./survey";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    events,
    connections,
    survey
  },
});
