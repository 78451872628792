import axios from "axios";
import environment from "./../../env";
export default {
  async sendInvitation(payload) {
    try {
      await axios.post(environment.environment.api_url + "/api/signed_url", payload);
      return "OK";
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  },
  async dropGuests(id = null) {
    return axios.delete(
      id
        ? `${environment.environment.api_url}/api/signed-url/${id}`
        : `${environment.environment.api_url}/api/signed-url`
    );
  },
  async getActiveGuests() {
    return axios.get(`${environment.environment.api_url}/api/signed-url`);
  },
  async toggleGuestChat(payload) {
    return axios.put(
      `${environment.environment.api_url}/api/signed_url/${payload.id}`,
      payload
    );
  },
};

